import React from 'react';
import './App.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './Theme';
import { SnackbarProvider } from 'notistack';
import { AppRoutes } from './Routes/Routes';
import { StateMachineProvider } from 'little-state-machine';
import { _createStore } from './Stores';
import { ErrorProvider } from './Providers';
import { ErrorNotifier } from './Components/Organisms/Popups/Error/error.notifier';
import { PopupProvider } from './Providers';
import {CheckCircleOutline, ErrorOutline, HighlightOff} from "@mui/icons-material";

_createStore();

function App() {
  React.useEffect(() => {
   var _mtm = (window as any)._mtm = (window as any)._mtm || [];
   _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
   var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
   g.async=true; g.src='https://cdn.matomo.cloud/tech4work.matomo.cloud/container_TiErGYVN.js'; 
   if (s && s.parentNode) {
     s.parentNode.insertBefore(g,s);
   } else {
     console.error("Failed to insert script: s or s.parentNode is null.");
   }

  }, [])
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <StateMachineProvider>
      <SnackbarProvider
          maxSnack={3}
          autoHideDuration={3000}
          iconVariant={{
            success: <CheckCircleOutline style={{marginRight: 10}}/>,
            error: <HighlightOff style={{marginRight: 10}}/>,
            info: <ErrorOutline style={{marginRight: 10}}/>,
          }}
          anchorOrigin={{vertical: "top", horizontal: "center"}}>
        <PopupProvider>
          <ErrorProvider>
            <ErrorNotifier />
            <AppRoutes />
          </ErrorProvider>
        </PopupProvider>
        </SnackbarProvider>
      </StateMachineProvider>
    </ThemeProvider>
  );
}

export default App;
