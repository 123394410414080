import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ActionForm, ActionRead } from './ActionForm/action.form';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useUserProfile, usePopup, useActions } from '../../../../Hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { actionValidation } from './ActionForm/action.validation';
import { TabContext, TabPanel, Timeline } from '@mui/lab';
import { TimelineActionHistory } from '../../../Molecules/action-history.molecule';
import { Tabs, Tab } from '@mui/material';
import { Success } from '../../../Molecules';
import { BeneficiaryProfileContext } from '../../../../Providers';
import { GetActionDto, GetActionHistoryDto } from '../../../../Types/actions.type';
import APIAxios, { APIRoutes } from '../../../../API/api.axios';
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';

export const UserAction = () => {
  const { closePopup, popupMode } = usePopup('ActionPopup');
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [success, setSuccess] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<string>('form');
  const {
    selectAction,
    selectedAction,
  } = useUserProfile(BeneficiaryProfileContext);
  const { handleUpdate } = useActions();
  const methods = useForm<Partial<GetActionDto>>({
    resolver: yupResolver(actionValidation),
    mode: 'onChange',
  });
  const { isValid, dirtyFields } = methods.formState;
  const [searchParams] = useSearchParams();

  const handleClosePopup = useCallback(() => {
    selectAction();
    closePopup();
    handleUpdate();
  }, [closePopup, selectAction, handleUpdate]);

  const onSubmit = useCallback(
    async (data: Partial<GetActionDto>, actionId?: string) => {
      const id = searchParams.get('uid') || '';
      if (popupMode() === 'add') {
        try {
          let res = await APIAxios({...APIRoutes.POSTBeneficiaryAction(id), data: {
            ...data
          }})
          if (res.data) {
            enqueueSnackbar("L'action a bien été créée", { variant: "success" });
            window.dispatchEvent(new Event("reload-actions"))
            closePopup();
          } else {
            enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.", { variant: "error" })
          }
        } catch (err) {
          enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.", { variant: "error" })
        }
      } else {
        try {
          let res = await APIAxios({...APIRoutes.PATCHAction(actionId || ''), data: {
            ...data
          }});
          if (res.data) {
            enqueueSnackbar("L'action a bien été modifiée", { variant: "success" })
            window.dispatchEvent(new Event("reload-actions"))
            closePopup();
          }
        } catch (err) {
          enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.", { variant: "error" })
        }
      }
    }, []);

  if (success) {
    return (
      <Success
        title={popupMode() === 'add' ? "L'action a été ajoutée" : "L'action a été modifiée"}
        popupId="ActionPopup"
        closeHandler={handleClosePopup}
      />
    );
  }

  return (
    <TabContext value={tabIndex}>
      <Grid container padding={isSm ? theme.spacing(2) : theme.spacing(6)} justifyContent="center">
        {/* header */}
        <Grid container justifyContent="space-between" marginBottom={popupMode() === 'add' ? 4 : 0}>
          <Typography variant="h2">
            {popupMode() === 'add' && 'Ajouter une action'}
            {popupMode() === 'edit' && "Voir / modifier l'action"}
            {popupMode() === 'readonly' && "Détails de l'action"}
          </Typography>
          <Button variant="icon" color="primary" onClick={handleClosePopup}>
            <CloseRoundedIcon />
          </Button>
        </Grid>
        {/* content */}
        {popupMode() === 'add' && (
          <FormProvider {...methods}>
            <ActionForm />
          </FormProvider>
        )}
        {popupMode() !== 'add' && (
          <>
            <Tabs
              style={{
                margin: !isSm ? '10px auto 0 0' : 'auto',
                paddingLeft: !isSm ? theme.spacing(3) : 0,
                width: '100%',
              }}
              value={tabIndex}
              onChange={(e, value) => setTabIndex(value)}
              aria-label="action informations"
              indicatorColor="primary"
              scrollButtons={!isSm}
              allowScrollButtonsMobile
            >
              <Tab value="form" label="Général" />
              <Tab value="history" label="Historique des modifications" />
            </Tabs>
            <TabPanel sx={{ padding: isSm ? 2 : 'inherit' }} value="form" style={{ width: '100%' }}>
              {popupMode() === 'edit' &&  selectedAction && (
                <FormProvider {...methods}>
                  <ActionForm />
                </FormProvider>
              )}
              {popupMode() === 'readonly' && <ActionRead />}
            </TabPanel>
            <TabPanel sx={{ padding: isSm ? 2 : 'inherit' }} value="history" style={{ width: '100%' }}>
              <Grid container item justifyContent="center">
                {selectedAction && selectedAction.actionHistories.length > 0 ? (
                  <Timeline
                    position="right"
                    sx={{
                      [theme.breakpoints.down('sm')]: {
                        width: '100%',
                        padding: 0,
                        overflowX: 'scroll',
                      },
                    }}
                  >
                    {selectedAction.actionHistories.sort((a: GetActionHistoryDto, b: GetActionHistoryDto) => {
                      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                    }).map((action, index, arr) => (
                      <TimelineActionHistory key={index} actionHistory={action} isLast={index === arr.length - 1} />
                    ))}
                  </Timeline>
                ) : (
                  <Typography variant="body2" component="div">
                    Il n&apos;y a pas encore eu de modifications sur ce dispositif.
                  </Typography>
                )}
              </Grid>
            </TabPanel>
          </>
        )}
        {/* footer */}
        {popupMode() !== 'readonly' && (
          <Grid item container xs={12} justifyContent="flex-end" marginTop={2}>
            <Button
              variant="contained"
              disabled={!isValid || (popupMode() === 'edit' && !Object.keys(dirtyFields).length)}
              onClick={methods.handleSubmit((data) => {
                  onSubmit(data, selectedAction?.id)  
                })
              }
            >
              {popupMode() === 'add' && 'Ajouter au parcours'}
              {popupMode() === 'edit' && 'Enregistrer'}
            </Button>
          </Grid>
        )}
      </Grid>
    </TabContext>
  );
};
